import React from 'react'
import ReactMarkdown from "react-markdown"
import styled from 'styled-components'
import rehypeRaw from 'rehype-raw'

const StyledMarkdown = styled(ReactMarkdown)`
  width: 100%;
  overflow-wrap: break-word;
`

export default function Markdown({ content }) {
  return (
    <StyledMarkdown rehypePlugins={[rehypeRaw]} className="markdown">{content}</StyledMarkdown>
  )
}